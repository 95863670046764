import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import CustomButton from '../../components/custom-button';
import '../../styles/applications/soil-tie-up.scss';
import PlayIcon from '../../images/play-icon.svg';

const SoilTieUp = () => {
  const config = {
    config: {
      file: {
        attributes: {
          controlsList: 'nodownload',
        },
      },
    },
    playIcon: <img src={PlayIcon} alt="playicon" />,
    url: 'https://www.youtube.com/watch?v=jD_Kid4EUAA',
    light:
      'https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara-testimonial-min.jpeg',
    playing: true,
    controls: true,
    width: '100%',
  };
  return (
    <Layout>
      <SEO
        title="Prevent Soil Fixation | Ostara"
        description="Maintain nutrient availability for growing crops, all season long, with Root-Activated fertilizers that gradually release nutrients to prevent soil fixation and tie up."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Prevent Soil Fixation"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 100]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack maxW="50rem" minH="250px" spacing={6}>
                  <h1>Prevent Soil Fixation</h1>
                  <p>
                    Maintain nutrient availability for growing crops, all season
                    long.
                  </p>
                  <div className="button-wrappers">
                    <CustomButton to="/contact">CONTACT US</CustomButton>
                  </div>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/soil-hero-img-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={4} className="soil-tie-up-body-container">
          <Flex direction="column">
            <Container
              className="soil-tie-up-body-content-1--container"
              maxW="container.lg"
              marginTop={50}
            >
              <div className="soil-tie-up-body-content-1--heading">
                <h3>
                  Crystal Green<sup>®</sup> Fertilizers Release Nutrients
                  According to Crop Demand to Prevent Soil Fixation
                </h3>
                <p>
                  Crops seldom take up more than 25% of the phosphorus
                  fertilizer in the year of application. The remaining 75%
                  potentially becomes tied-up and unavailable to growing plants.
                  With patented technology, Crystal Green solves the issue of
                  fixation, keeping nutrients available for when your crops need
                  them.
                </p>
              </div>
              <StaticImage
                className="hero-img"
                placeholder="transparent"
                src="../../images/soil-fixation2-min.png"
                alt="Ostara img"
              />
            </Container>
          </Flex>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>

          <Container className="soil-tie-up-body-2--container" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <Flex direction="column" className="soil-tie-up-body-content-2">
                <h3>Nutrients on Demand</h3>
                <p>
                  Provide roots with the nutrients they need, when they need
                  them, all season long. Crystal Green releases nutrients only
                  in response to organic acids released from crop demand, unlike
                  conventional phosphorus fertilizers which often end up lost in
                  the soil.
                </p>
              </Flex>
              <StaticImage
                placeholder="transparent"
                src="../../images/prevent-soil-fixation-nutrients-on-demand.png"
                alt="Ostara icons"
              />
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>

          <Container className="soil-tie-up-body-2--container" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <StaticImage
                placeholder="transparent"
                src="../../images/soil-tie-up-img2.png"
                alt="Ostara icons"
              />
              <Flex direction="column" className="soil-tie-up-body-content-2">
                <h3>Minimize Loss to Maximize Uptake</h3>
                <p>
                  Crystal Green prevents phosphorus from dissolving into the
                  soil where it can react with iron, aluminum or calcium and
                  become tied-up and unavailable to plants. This can result in
                  soils with high levels of phosphate with crops showing signs
                  of phosphate deficiency.
                </p>
              </Flex>
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>

          <Container
            className="soil-tie-up-body-3--container"
            maxW="container.md"
          >
            <div className="soil-tie-up-body-3--heading">
              <h3>
                Study Results: Crystal Green Maintains Phosphate Availability
                All Season
              </h3>
              <p>
                Crystal Green Crop Driven Release™ fertilizers are sparingly
                water soluble and only release phosphate in response to organic
                acids produced by roots, ensuring phosphate remains available
                throughout importing growth periods of the season.
              </p>
            </div>
          </Container>
          <Container
            className="soil-tie-up-body-3--arrow-lines key-top-box"
            maxW="container.lg"
          />
          <Container maxW="container.lg" className="soil-tie-up-body-3--chart1">
            <StaticImage
              placeholder="transparent"
              src="../../images/Nutrients-Release-Graph-min.png"
              alt="Ostara icons"
            />
          </Container>
          <Container className="key-down-box" maxW="container.lg" />
          <Container
            className="soil-tie-up-body-3--container"
            maxW="container.md"
          >
            <div className="soil-tie-up-body-3--heading">
              <h3>
                Study Results: Crystal Green Prevents Phosphate from Leaching
                into Soil
              </h3>
              <p>
                A study of cumulative orthophosphate in leachate as affected by
                phosphorus source shows Crystal Green is considerably less prone
                to leaching when compared to water soluble P sources.
              </p>
            </div>
          </Container>
          <Container
            className="soil-tie-up-body-3--arrow-lines key-top-box"
            maxW="container.lg"
          />
          <Container maxW="container.lg" className="soil-tie-up-body-3--chart2">
            <StaticImage
              placeholder="transparent"
              src="../../images/Cumulative-OR-Graph.png"
              alt="Ostara icons"
            />
          </Container>
          <Container className="line-end" maxW="container.lg" />
        </Box>

        <Box w="100%" p={4} className="soil-tie-up-body-container">
          <Container
            className="soil-tie-up-body-content-3--container"
            maxW={1400}
          >
            <SimpleGrid
              marginTop={[16, 40]}
              marginBottom={[16, 0]}
              columns={{ md: 1, lg: 2 }}
              spacing={20}
              className="product-player-container"
            >
              <Box w="100%" p={4}>
                <h1>Research Proven</h1>
                <p>
                  In this video, Dr. Fred Below, professor at the University of
                  Illinois, discusses his research showing how Crystal Green
                  uses phosphorus more efficiently to help increase yield.
                </p>
              </Box>
              <Box w="100%" p={4}>
                <ReactPlayer className="module-video" {...config} />
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="soil-bottom-hero-container">
          <div className="overlay" />
          <Container maxW={1400}>
            <Stack
              paddingTop={[16, 150]}
              paddingBottom={[16, 150]}
              maxW="40rem"
              spacing={6}
            >
              <h2>Prevent Soil Fixation with Crystal Green Fertilizers</h2>
              <p>
                Crystal Green’s Crop Driven Release™ fertilizers only release
                nutrients in response to plant needs, so phosphorus doesn’t
                dissolve into the soil, tie-up and become unavailable to crops.
              </p>
              <CustomButton maxW={180}>
                <Link to="/product/crystal-green">LEARN MORE</Link>
              </CustomButton>
            </Stack>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default SoilTieUp;
